<template>
  <div class="demand">
    <div class="top">需求清单</div>
    <div>
      <div class="title">
        <van-row class="titlehr">
          <van-col span="3">序号</van-col>
          <van-col span="5">类别</van-col>
          <van-col span="5">名称</van-col>
          <van-col span="6">内容</van-col>
          <van-col span="5">负责人</van-col>
        </van-row>
      </div>
      <div class="tableBg">
        <table border="1">
          <tr v-for="item in list" :key="item">
            <td class="tabletr" style="width: 3%">{{ item.index }}</td>
            <td class="tabletr" style="width: 5%">{{ item.TKName }}</td>
            <td class="tabletr" style="width: 5%">{{ item.Name }}</td>
            <td class="tabletr" style="width: 5%">{{ item.Content }}</td>
            <td class="tabletr" style="width: 5%">{{ item.Leader }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Col, Row } from "vant";
Vue.use(Col);
Vue.use(Row);
import {WeGetServiceList} from '@/api/yeguang';
export default {
  data() {
    return {
      pagefrom: { page: 1, limit: 1000, type: 1 },
      list: [],
    };
  },
  created() {
    this.ServiceList();
  },
  methods: {
    ServiceList() {
      WeGetServiceList(this.pagefrom).then((res) => {
        this.list = res.data.data;
        for (var i = 0; i < this.list.length; i++) {
          this.list[i].index = i + 1;
        }
      });
    },
  },
}
</script>
<style>
.demand .top {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 12px;
  background: rgb(110, 118, 235);
  color: #fff;
}
.demand .title {
  background: rgb(110, 118, 235);
  color: #fff;
}
.demand .title .titlehr {
  padding: 10px 5px;
}
.demand .title .titlehr div {
  text-align: center;
}
.tableBg tr:nth-child(2n) {
  background: rgb(226, 229, 245);
}
.tabletr {
  font-size: 13px;
  padding: 10px 3px;
  text-align: center;
}
</style>